<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <!-- 搜索 -->
        <div class="mainList_operation_search">
          <span
            >姓名：
            <el-input
              class="search_input"
              v-model="parameter.userName"
              clearable
              placeholder="请输入姓名" />
          </span>
          <span
            >离职方式：
            <el-select
              class="search_input"
              v-model="parameter.departType"
              placeholder="请选择离职方式"
              filterable
              clearable>
              <el-option
                v-for="item in dictData.departStatus"
                :key="item.dictVal"
                :label="item.dictName"
                :value="item.dictVal">
              </el-option>
            </el-select>
          </span>
          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)">
            搜索
          </el-button>
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <!-- 表格展示 -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading">
            <el-table-column align="center" prop="userName" label="姓名" />
            <el-table-column align="center" prop="departmentName" label="部门" />
            <el-table-column align="center" prop="postName" label="岗位" />
            <el-table-column
              align="center"
              sortable
              min-width="120"
              prop="departDate"
              label="最后工作日">
              <template slot-scope="scope">
                {{ scope.row.departDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              min-width="120"
              prop="departReason"
              label="离职原因" />
            <el-table-column
              align="center"
              sortable
              min-width="120"
              prop="departType"
              label="离职方式">
              <template slot-scope="scope">
                {{ scope.row.departType | dict(dictData.departStatus) }}
              </template>
            </el-table-column>
            <el-table-column prop="departFiles" label="离职附件" align="center">
              <template slot-scope="scope">
                <template v-for="item in scope.row.departFiles">
                  <div>
                    <a
                      target="_blank"
                      style="text-decoration: revert; color: #409eff"
                      :href="`${filepath}${item.attachmentPath}`">
                      {{ item.attachmentName }}
                    </a>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="title" align="center" fixed="right" width="70" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="detail(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="total">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize" />
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :options="form" :isDepart="true" :isShow.sync="detailDialog" v-show="detailDialog"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('@/views/system/user/Edit.vue'),
    UploadList: () => import('@/components/upload/ContractList.vue'),
  },
  data() {
    return {
      parameter: {
        //获取表格
        pageNow: 1,
        pageSize: 10,
        userName: '',
      },
      pageSize: 0, //当前页面
      form: {}, //具体某条信息
      tableData: [], //离职人员列表
      total: 0, //总条数
      loading: false,
      dictData: {}, //字典数据
      detailDialog: false, //编辑页面
      filepath: process.env.VUE_APP_FILEPATH,
    }
  },
  computed: {
    ...mapState({ userInfo: state => state.user.userInfo }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData() //获取列表
    this.$api.dict
      .listSysDictData('DEPART_STATUS', true) //获取离职方式字典
      .then(res => {
        this.dictData.departStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  provide() {
    return {
      re: this.re,
    }
  },
  methods: {
    // 获取列表
    getData() {
      this.loading = true
      this.$api.user
        .departList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data.records
            this.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 编辑
    detail(row) {
      this.$api.user
        .departDeatail(row.staffId)
        .then(res => {
          this.form = res.data[0]
          this.form.id = this.form.staffId //离职人员信息修改接口的id为staffId
          this.form.departId = row.id //离职信息修改接口的id为离职id
          if (!this.form.labelList) this.form.labelList = []
          this.detailDialog = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    //关闭弹窗，返回原页面
    re() {
      this.detailDialog = false
      this.form = {}
      this.getData()
    },

    // 表格行数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    // 换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.parameter.pageNow = 1
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.userName = ''
      this.parameter.departType = ''
      this.pageChangeHandler(1)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';

.search_input {
  width: 165px;
  margin-right: 10px;
}

.dialog_table {
  margin: 10px 1px;
  width: 700px;
}
</style>
